import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    component: () => import('pages/LoginPage.vue'),
    name: 'login',
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/UserIndexPage.vue'),
        name: 'home',
      },
      {
        path: 'matches',
        component: () => import('pages/MatchesPage.vue'),
        name: 'matches',
      },
      {
        path: 'songs',
        children: [
          {
            path: '',
            component: () => import('pages/SongsPage.vue'),
            name: 'songs',
          },
          {
            path: ':id([0-9a-f]{8}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{4}-?[0-9a-f]{12})',
            component: () => import('pages/SongPage.vue'),
            name: 'song',
          },
          {
            path: ':invalidId',
            redirect: { name: 'songs', replace: true, params: {} },
          },
        ],
      },
      {
        path: 'stations',
        children: [
          {
            path: '',
            component: () => import('pages/StationsPage.vue'),
            name: 'stations',
          },
          {
            path: ':slug',
            component: () => import('pages/StationPage.vue'),
            name: 'station',
          },
        ],
      },
      {
        path: 'user',
        children: [
          {
            path: 'notifications',
            component: () => import('pages/NotificationsPage.vue'),
            name: 'notifications',
          },
          {
            path: 'export',
            component: () => import('pages/ExportPage.vue'),
            name: 'export',
          },
        ],
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
