import { initializeApp } from 'firebase/app';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { boot } from 'quasar/wrappers';
import { Ref, computed, ref } from 'vue';
import { until } from '@vueuse/core';
import * as Sentry from '@sentry/vue';
import * as gtag from 'vue-gtag';

export const firebaseApp = initializeApp({
  apiKey: 'AIzaSyA862sTHVthRb7lwSb99mINYTYKgfSm52c',
  authDomain: 'taklit-cb720.firebaseapp.com',
  databaseURL: 'https://taklit-cb720.firebaseio.com',
  projectId: 'taklit-cb720',
  storageBucket: 'taklit-cb720.appspot.com',
  messagingSenderId: '856434027747',
});

export const auth = getAuth(firebaseApp);

const currentUser = ref<User | null | undefined>();

export default boot(() => {
  onAuthStateChanged(auth, async (user) => {
    currentUser.value = user;
    if (user !== undefined) {
      Sentry.setUser(user ? { id: user.uid, email: user.email || '' } : null);
      gtag.set({ user_id: user?.uid, email: user?.email });
    }
  });
});

export function useCurrentUser(): Ref<User | null | undefined> {
  return computed(() => currentUser.value);
}

export async function getCurrentUser(): Promise<User | null> {
  const currentUser = useCurrentUser();
  return await until(currentUser).not.toBeUndefined();
}

export type FirebaseUser = User;
