import { boot } from 'quasar/wrappers';
import axios from 'axios';

import { Api } from 'src/lib/api';

const axiosInstance = axios.create({
  baseURL: '/api',
  timeout: 60000,
});

export default boot(() => {});

const api = new Api(axiosInstance);

export { api, axiosInstance };
