import { AxiosInstance } from 'axios';
import { Match, Station, Song, SongUpdate, TopSong } from './models';
import { mapValues, pickBy } from 'lodash-es';
import { formatISO } from 'date-fns';
import { getCurrentUser } from 'src/boot/firebase';

type RequestConfig = {
  signal?: AbortSignal;
};

function serializeDate(date: Date) {
  return formatISO(date, { representation: 'date' });
}

export class Api {
  #axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.#axios = axios;
  }

  async listMatches(
    {
      offset,
      limit,
      songId,
      stations,
    }: {
      offset: number;
      limit: number;
      songId?: string;
      stations?: string[];
    },
    { signal }: RequestConfig = {},
  ) {
    return (
      await this.#axios.get<Match[]>('matches', {
        params: {
          offset,
          limit,
          songId,
          stations: stations !== undefined ? stations.join(',') : undefined,
        },
        signal,
      })
    ).data;
  }

  async getMatchRecordingLink(matchId: string, { signal }: RequestConfig = {}) {
    return (
      await this.#axios.get<string>(`matches/${matchId}/recording_link`, {
        signal,
      })
    ).data;
  }

  async listStations(
    data: {
      order: 'name' | 'latestMatchTime';
      offset?: number;
      limit?: number;
      search?: string;
    },
    { signal }: RequestConfig = {},
  ) {
    return (
      await this.#axios.get<Station[]>('stations', { params: data, signal })
    ).data;
  }

  async getStation(slug: string) {
    return (await this.#axios.get<Station>(`stations/${slug}`)).data;
  }

  async updateStation(slug: string, data: { notificationsEnabled: boolean }) {
    return (await this.#axios.patch<Station>(`stations/${slug}`, data)).data;
  }

  async login() {
    const currentUser = await getCurrentUser();
    const token = await currentUser?.getIdToken();
    await this.#axios.get('me/login', {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async logout() {
    await this.#axios.get('me/logout');
  }

  async createTelegramToken() {
    return (await this.#axios.post<{ token: string }>('me/telegram_token')).data
      .token;
  }

  async listSongs(
    data: { offset: number; limit: number; search: string },
    { signal }: RequestConfig = {},
  ) {
    return (
      await this.#axios.get<{
        count: number;
        songs: Song[];
      }>('songs', { params: data, signal })
    ).data;
  }

  async listTopSongs(
    data: { fromDate: Date; toDate?: Date } | { year: number; month?: number },
    { signal }: RequestConfig = {},
  ) {
    return (
      await this.#axios.get<TopSong[]>('songs/top', {
        params:
          'fromDate' in data ? mapValues(pickBy(data), serializeDate) : data,
        signal,
      })
    ).data;
  }

  async createSong(data: { fileName: string }) {
    return (
      await this.#axios.post<{ id: string; uploadUrl: string }>('songs', data)
    ).data;
  }

  async uploadSong(id: string, data: { name: string; fileName: string }) {
    await this.#axios.post(`songs/${id}/upload`, data);
  }

  async getSong(id: string) {
    return (await this.#axios.get<Song>(`songs/${id}`)).data;
  }

  async updateSong(id: string, data: SongUpdate) {
    return (await this.#axios.patch<Song>(`songs/${id}`, data)).data;
  }

  async deleteSong(id: string) {
    await this.#axios.delete(`songs/${id}`);
  }
}
