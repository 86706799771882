<template>
  <router-view />
</template>

<script setup lang="ts">
import { useQuasar } from 'quasar';
import { onMounted } from 'vue';
import * as gtag from 'vue-gtag';
import * as Sentry from '@sentry/vue';
import { isAxiosError } from 'axios';
import { signOut } from 'firebase/auth';
import { useRouter } from 'vue-router';

import { auth, useCurrentUser } from './boot/firebase';
import { api } from './boot/axios';
import { watchOnce } from '@vueuse/core';

const $q = useQuasar();

function openErrorDialog() {
  $q.dialog({
    persistent: true,
    ok: 'רענון',
    message: 'ארעה שגיאה לא צפויה, ניתן לנסות שנית מאוחר יותר.',
    title: 'שגיאה ⚠️⚠️⚠️',
    class: 'text-center',
  }).onOk(() => {
    window.location.reload();
  });
}

const router = useRouter();

watchOnce(useCurrentUser(), (user) => {
  // at app startup, if user is already logged in,
  // send another login request to refresh the session cookie
  if (user) {
    setTimeout(() => {
      api.login();
    }, 1000 * 5);
  }
});

window.addEventListener('unhandledrejection', async (ev) => {
  if (isAxiosError(ev.reason)) {
    if (ev.reason.response?.status === 401) {
      await signOut(auth);
      router.replace({ name: 'login' });
    } else {
      openErrorDialog();
    }
  }
});

onMounted(() => {
  navigator.serviceWorker?.getRegistrations?.().then(function (registrations) {
    // remove remnants of old website, if they exist
    if (registrations.length > 0) {
      gtag.event('unregistering-service-workers');
      Sentry.captureMessage('Found existing service worker');
    }
    for (let registration of registrations) {
      registration.unregister();
    }
  });
});
</script>
