import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import { signInWithCustomToken } from 'firebase/auth';

import { auth, getCurrentUser } from 'boot/firebase';
import { api } from 'boot/axios';

import routes from './routes';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.beforeEach(async (to) => {
    // returns the name of a page to redirect to, or `undefined` if the navigation can continue
    async function redirect(): Promise<string | undefined> {
      if (typeof to.query.token === 'string') {
        await signInWithCustomToken(auth, to.query.token);
        await api.login();
        return 'home';
      }

      const currentUser = await getCurrentUser();

      if (currentUser === null && to.name !== 'login') {
        return 'login';
      } else if (currentUser !== null && to.name === 'login') {
        return 'home';
      }
    }

    const redirectTo = await redirect();
    if (redirectTo) {
      return { replace: true, name: redirectTo };
    }
  });

  return Router;
});
